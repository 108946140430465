import dorucak from "../images/meni-dorucak.jpg"
import predjela from "../images/meni-predjela.jpg"
import bbq from "../images/meni-bbq.jpg"
import dezert from "../images/meni-dezert.jpg"
import glavnaJela from "../images/meni-glavna-jela.jpg"
import obrokSalate from "../images/meni-obrok-salate.jpg"
import pasteIRizota from "../images/meni-paste-i-rizota.jpg"
import supe from "../images/meni-supe.jpg"

export const MenuContent = [
  {
    number: 1,
    title: "starteri (sharing plates)",
    link: "starteri-sharing",
    photo: predjela,
    meals: [
      {
        title: 'Suve šljive u slanini sa orasima',
        description: 'Suve šljive punjene orasima i flambirane na puteru i Branko šljivi',
        alergenes: 'Gluten free, orašasti plodovi, alcohol',
        price: '620 rsd'
      },
      {
        title: 'Suve kajsije u slanini sa pistaćima',
        description: 'Suve kajsije punjene pistaćima i flambirane na puteru i barikiranoj kajsijevači',
        alergenes: 'Gluten free, orašasti plodovi, alkohol',
        price: '720 rsd'
      },
      {
        title: 'Prolećne rolnice sa orasima i karijem',
        description: 'Marinirano žilijen povrće sa tostiranim mlevenim orasima i karijem, umotano u pirinčane kore, servirane sa redukcijom soja sosa i đumbira',
        alergenes: 'Gluten, orašasti plodovi.',
        price: '720 rsd'
      },
      {
        title: 'Meze uz rakiju za dve osobe',
        description: 'Njeguški pršut, zlatarski sir, pate od čvaraka, trljanica, somoborka, kornišoni, kalamati, hleb.',
        alergenes: 'mleko, gluten, beli luk, ljuto',
        price: '1740 rsd'
      },
      {
        title: 'Pate od čvaraka',
        description: 'Domaći pate od duvan čvaraka, serviran sa štapićima kornišona, somborka paprikom i Brioche hlebom.',
        alergenes: 'gluten, mleko, beli luk, ljuto',
        price: '1190 rsd'
      },
      {
        title: 'Riblji terin',
        description: 'Terin od fileta lososa i tune sa miksom pečenog povrća, serviran uz tostirani Brioche hleb i miks zelenih salata.',
        alergenes: 'gluten, beli luk, med',
        price: '1490 rsd'
      },
      {
        title: 'Zli jezici',
        description: 'Gioza kore punjene pikantnim raguom od junećih jezika i prelivene domaćim ljutim demiglasom.',
        alergenes: 'Ljuto,Gluten, beli luk, med',
        price: '960 rsd'
      },
      {
        title: 'Grilovani sirevi (miročki ili koziji)',
        description: 'Grilovani sir na rukoli sa salsom od grilovane crvene paprike, kapra i peršuna',
        alergenes: 'Gluten free, beli luk, mleko',
        price: '860 rsd / 1060 rsd'
      },
      {
        title: 'Gioze sa pačetinom',
        description: 'Gioza kore punjene cepkanom pačetinom i mladim lukom, pržene na puteru, servirane sa mikro biljem i slatko kiselim sosom od šljive',
        alergenes: 'Gluten, mleko',
        price: '1290 rsd'
      },
      {
        title: 'Zapečena burata',
        description: 'Domaća burata zapečena u tembalu sa mariniranim i prženim štapićima bukovače, čeri paradajzom, prelivena ajoli sosom i začinskim biljem',
        alergenes: 'Gluten, beli luk, mleko, jaja',
        price: '1090 rsd'
      }
    ]
  },
  {
    number: 2,
    title: "starteri (specijaliteti)",
    link: "starteri-specijaliteti",
    photo: pasteIRizota,
    meals: [
      {
        title: 'Falafel salata',
        description: 'Pržene loptice falafela, čeri paradajz, jogurt-feta umak, čips od crvenog luka, kalamata masline, miks zelenih salata sa limun dresingom.',
        alergenes: 'Gluten free, mleko, beli luk, med',
        price: '1090 rsd'
      },
      {
        title: 'Njoke sa spanaćem i tikvicama',
        description: 'Sotirane njoke sa spanaćem, tikvicama, belim lukom I parmezanom.',
        alergenes: '',
        price: '1190 rsd'
      },
      {
        title: 'Njoke sa mangulicom',
        description: 'Domaće njoke u pikantnom sosu od kobasica i salse od paprike servirane sa grilovanim kozijim sirom.',
        alergenes: 'Gluten free, beli luk, mleko',
        price: '1290 rsd'
      },
      {
        title: 'Taljatele sa piletinom',
        description: 'Testenina u krem sosu sa piletinom, pečenim čerijem, domaćom pestom od bosiljka, peršuna i parmezana sa Homoljskih planina.',
        alergenes: 'mleko, jaja, gluten, beli luk',
        price: '1190 rsd'
      },
      {
        title: 'Grilovani miročki sir na salati',
        description: 'Kockice grilovanog miročkog sira, rukola, zelena salata, salsa od pečenih paprika I kapra, krutoni, ulje od začinskog bilja.',
        alergenes: 'mleko, beli luk, gluten',
        price: '1190 rsd'
      },
      {
        title: 'Cezar sa gvakamolama',
        description: 'Grilovani marinirani pileći file sečen na taljatu sa čipsom od pančete, serviran na gvakamolama uz miks zelenih salata začinjenih cezar sosom, domaćim parmezanom i začinjenim brusketima',
        alergenes: 'Gluten, jaja, mleko, beli luk',
        price: '1490 rsd'
      },
      {
        title: 'Grilovani biftek na salati',
        description: 'Grilovani odresci bifteka iz marinade, servirani na miksu zelenih salata, začinjeni dresingom od šljive i grejpa sa brioš krutonima i flambiranim mariniranim tikvicama.',
        alergenes: 'Gluten, mleko, beli luk, alkohol',
        price: '1890 rsd'
      },
      {
        title: 'Rižoto sa cveklom i kozijim sirom',
        description: 'Rižoto sa pečenom cveklom, štapićima bukovače i grilovanim kozijim sirom.',
        alergenes: 'Gluten free,mleko',
        price: '1190 rsd'
      },
      {
        title: 'Rižoto sa džemom od crvenog luka i pistaća',
        description: 'Kremasti rižoto sa piletinom, džemom od luka, goronzolom i pistaćima.',
        alergenes: 'mleko, orašaste plodove, gluten free',
        price: '1290 rsd'
      },
      {
        title: 'Gravadlax',
        description: '15dana hladno sušeni fileti lososa u 4 vrste bibera, serviran na miksu zelenih salata i preliven slatkokiselim sosom od šljive i začinskim biljem.',
        alergenes: 'Gluten free, beli luk',
        price: '1690 rsd'
      },
      {
        title: 'Tatar tuna',
        description: 'Ručno seckani tatar odležale tune u miksu začina soja sosa i đumbira začinjen dižon senfom, mladim lukom i mešavinom bibera sa svežim gvakamolama i Brioche hlebom.',
        alergenes: 'Gluten, beli luk,',
        price: '2290 rsd'
      },
      {
        title: 'Tatar biftek',
        description: 'Ručno seckani odležali biftek sa selekcijom začina i Branko šljivovicom, serviran sa puterom i brioš hlebom.',
        alergenes: 'Gluten, mleko, ljuto, beli luk, jaja',
        price: '2390 rsd'
      },
    ]
  },
  {
    number: 3,
    title: "potaži i čorbe",
    link: "potazi-i-corbe",
    photo: pasteIRizota,
    meals: [
      {
        title: 'Potaž dana',
        description: '',
        alergenes: 'Gluten free',
        price: '400 rsd'
      },
      {
        title: 'Domaća goveđa supa',
        description: 'Supa sa povrćem i domaćim rezancima.',
        alergenes: 'Gluten, jaja',
        price: '400 rsd'
      },
      {
        title: 'Bela biftek čorba',
        description: 'Čorba od povrća i seckanog bifteka servirana sa legirom.',
        alergenes: 'Gluten, jaja, mleko',
        price: '490 rsd'
      }
    ]
  },
  {
    number: 4,
    title: "grill",
    link: "grill",
    photo: pasteIRizota,
    meals: [
      {
        title: 'Ćevapi na kajmaku (mala ili velika porcija)',
        description: 'Juneći ćevapi servirani u tembalu na kajmaku sa začinjenim krompirićima.',
        alergenes: 'Gluten free, mleko.',
        price: '990 rsd / 1390 rsd'
      },
      {
        title: 'Pljeskavica sa miročkim sirom i džemom od luka',
        description: 'Juneće meso umešano sa seckanim miročkim sirom, pančetom i crvenim lukom, servirana na začinjenim krompirićima, pokrivena rukolom, parmezanom, čeri paradajzom i džemom od luka.',
        alergenes: 'Gluten free, mleko, alcohol',
        price: '1390 rsd'
      },
      {
        title: 'Pljeskavica u tavi sa aromatizovanim kajmakom.',
        description: 'Juneće pljeskavica pečena na grilu i udinstana u kajmaku sa marinadom od bosiljka, peršuna i belog luka. Servirana sa salatom od paradajza i baby spanaća.',
        alergenes: 'Gluten free, mleko, beli luk.',
        price: '1490 rsd'
      },
      {
        title: 'Marinirani pileći file',
        description: 'Grilovani pileći file serviran na kremi od paprike i bosiljka sa miksom grilovanog povrća i marinadom od belog luka i peršuna.',
        alergenes: 'Gluten free i beli luk',
        price: '1290 rsd'
      },
      {
        title: 'Kobasica od mangulice',
        description: 'Domaća sveža kobasica od mangulice servirana sa legiranim krompirom u dižon senfu sa baby spanaćem.',
        alergenes: 'Gluten free, mleko, beli luk, ljuto.',
        price: '1440 rsd'
      },
      {
        title: 'Marinirani odresci rozbifa na grilu',
        description: '21 dan odležali rozbif u miksu začina, serviran na legiranom krompiru sa dižon senfom i baby spanaćem.',
        alergenes: 'Gluten free, mleko.',
        price: '1690 rsd'
      }
    ]
  },
  {
    number: 5,
    title: "specijaliteti",
    link: "specijaliteti",
    photo: pasteIRizota,
    meals: [
      {
        title: 'Teleća džigerica sa lešnikom i medovinom',
        description: 'Teleća džigerica umotana u maramicu i nadevena džemom od crvenog luka, suvih šljiva i lešnika. Servirana na pireu od šargarepe i jabuke uz njoke pržene na puteru i belom luku.',
        alergenes: 'Gluten, alkohol, beli luk, orašasto.',
        price: '1290 rsd'
      },
      {
        title: 'Taljata od pačijih grudi',
        description: 'Suvidirane pačije grudi isečene na taljatu i servirane na sutlijašu od kokosovog mleka i limetinog lista, prelivene slatkokiselim sosom od šljive.',
        alergenes: 'Gluten, mleko.',
        price: '1890 rsd'
      },
      {
        title: 'Juneći obrazi u porto vinu',
        description: 'Suvidirani juneći obrazi u sosu od demiglasa, porto vina i svežih krušaka, servirani na pireu od špargle i krompira.',
        alergenes: 'Gluten free, alkohol.',
        price: '1790 rsd'
      },
      {
        title: 'Rozbif u biber sosu',
        description: '21 dan odležali rozbif u demiglasu sa 4 vrste bibera, serviran na pireu od celera i tartufate uz kockice musake od krompira',
        alergenes: 'Gluten, mleko, beli luk.',
        price: '1890 rsd'
      },
      {
        title: 'Redefinisani Osso buco',
        description: 'Juneći ribić u krem sosu od povrća sa domaćim demiglasom, serviran sa musakom od krompira i kockom sa goveđim želeom.',
        alergenes: 'Gluten free, mleko',
        price: '1790 rsd'
      },
      {
        title: 'File minjon sa sotiranim šparglama',
        description: 'Odležali biftek u sosu od demiglasa i fermentisanog belog luka, serviran sa sotiranim šparglama na puteru i tartufati.',
        alergenes: 'Gluten free, mleko, beli luk.',
        price: '3390 rsd'
      },
      {
        title: 'Tuna steak uvaljan u pistaće',
        description: 'Grilovani odrezak tune uvaljan u miks četiri vrste bibera, morsku so i mlevene pistaće serviran sa rukolom i spanaćem u nar-balzamiko dresingu.',
        alergenes: 'Gluten, med',
        price: '2890 rsd'
      }
    ]
  },
  {
    number: 6,
    title: "tradicionalna jela",
    link: "tradicionalna-jela",
    photo: obrokSalate,
    meals: [
      {
        title: 'Gulaš sa njokama i kajmakom',
        description: 'Juneći gulaš serviran sa domaćim njokama i zrelim kajmakom.',
        alergenes: 'Gluten free, alkohol, mleko.',
        price: '1290 rsd'
      },
      {
        title: 'Ragu od junećih repova',
        description: '18 sati kuvan ragu od junećih repova serviran sa domaćim njokama, parmezanom i začinskim biljem.',
        alergenes: 'Gluten, mleko, ljuto, beli luk, alkohol.',
        price: '1390 rsd'
      },
      {
        title: 'Konfitirana jagnjetina',
        description: 'Sporo pečena jagnjetina u dubokoj masti od mangulice začinjena provansalskim miksom začina, servirana u tembalu sa krompirićima.',
        alergenes: 'Gluten free, beli luk.',
        price: '1890 rsd'
      }
    ]
  },
  {
    number: 7,
    title: "salate i peciva",
    link: "salate-i-peciva",
    photo: dezert,
    meals: [
      {
        title: 'Šopska salata',
        description: '',
        alergenes: '',
        price: '420 rsd'
      },
      {
        title: 'Srpska salata',
        description: '',
        alergenes: '',
        price: '390 rsd'
      },
      {
        title: 'Miks zelenih salata',
        description: '',
        alergenes: '',
        price: '390 rsd'
      },
      {
        title: 'Kupus salata',
        description: '',
        alergenes: '',
        price: '350 rsd'
      },
      {
        title: 'Paradajz salata/sa sirom',
        description: '',
        alergenes: '',
        price: '390 rsd / 420 rsd'
      },
      {
        title: 'Slatka belolučana paprika',
        description: '',
        alergenes: '',
        price: '420 rsd'
      },
      {
        title: 'Ljuta belolučana paprika',
        description: '',
        alergenes: '',
        price: '240 rsd'
      },
      {
        title: 'Moravska salata',
        description: '',
        alergenes: '',
        price: '420 rsd'
      },
      {
        title: 'Kalamata masline porcija 80gr',
        description: '',
        alergenes: '',
        price: '290 rsd'
      },
      {
        title: 'Treljanica',
        description: '',
        alergenes: '',
        price: '420 rsd'
      },
      {
        title: 'Hleb porcija',
        description: '',
        alergenes: '',
        price: '120 rsd'
      },
      {
        title: 'Tostirani brioš hleb',
        description: '',
        alergenes: '',
        price: '360 rsd'
      }
    ]
  },
  {
    number: 8,
    title: "deserti",
    link: "deserti",
    photo: dezert,
    meals: [
      {
        title: 'Lenje gioze (mala ili velika porcija)',
        description: 'Gioza kore punjene sotiranim jabukama na puteru i medu, servirane sa maskarpone kremom i cimetom.',
        alergenes: 'Gluten, mleko, med.',
        price: '550 rsd'
      },
      {
        title: 'Čaša krempite',
        description: 'Redefinisana krempita servirana u čaši sa domaćim džemom od maline i redukcijom likera od maline.',
        alergenes: 'Gluten, mleko, alkohol.',
        price: '550 rsd'
      },
      {
        title: 'Brownie',
        description: 'Čokoladni brauni sa lešnikom i sladoledom od vanile',
        alergenes: 'Gluten, mleko, orašasti.',
        price: '640 rsd'
      },
      {
        title: 'Pečeni cheesecake sa limetom',
        description: 'Pečeni cheesecake sa suvidiranim listom limete, preliven domaćim džemom od borovnice.',
        alergenes: 'Mleko, gluten.',
        price: '640 rsd'
      }
    ]
  }
]

