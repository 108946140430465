import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import MenuItem from "../components/MenuItem"
import {MenuContent} from "../components/MenuContent"
import {MenuTabs} from "../components/MenuTabs"
import { SEO as Seo} from "../components/seo"

const MeniPage = () => {
  const [ activeTab, setActiveTab ] = React.useState(5);
  return (
    <Layout
      backArrow={true}
      bodyClasses={" flat-menu-page -white-third-bg meny-right "}
    >
      <Breadcrumbs currentPage="Meni" />
      <main className="main-content">
        <div className="container">
          <h1 className="title-of-page center-aligned">Jelovnik</h1>

          <div className="main-menu-section -bottom-indent">
            <div className="row">
              <div className="col-12">
                <div className="description-text intro-text center-aligned">
                  <p>Iako o ukusima ne treba raspravljati, kada bacite pogled na naš meni, rasprava je neizbežna, jer će vam sigurno biti teško da odaberete samo jedno jelo.</p>
                </div>
              </div>
            </div>

            <div className="d-md-none col-md-6">
              <video autoPlay muted loop controls playsInline width="100%">
                <source src="/instories_b684a40a-d1df-4aeb-84fe-cd1685cf1498.webm" type="video/webm" />
                <source src="/instories_b684a40a-d1df-4aeb-84fe-cd1685cf1498.mp4" type="video/mp4" />
              </video>
            </div>

            <MenuTabs
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />

            <div className="tab-content">
              {MenuContent.map((i) => (
                <div className={`tab-pane fade ${activeTab === i.number && " active show"}`} id={`${i.link}`} role="tabpanel" aria-labelledby={`${i.link}-tab`}>
                </div>
              ))}
              <div className="row">
                <div className="d-none d-md-block col-md-6">
                  <video autoPlay muted controls loop playsInline width="100%">
                    <source src="/instories_b684a40a-d1df-4aeb-84fe-cd1685cf1498.webm" type="video/webm" />
                    <source src="/instories_b684a40a-d1df-4aeb-84fe-cd1685cf1498.mp4" type="video/mp4" />
                  </video>
                </div>

                {MenuContent.map((i) => (
                  <div className="col-sm-12 col-md-6 col-lg-6 small-gutters align-items-end" style={{display: activeTab === i.number ? 'flex' : 'none', flexWrap: 'wrap', alignContent: 'start', paddingTop: '2rem'}}>
                    {i.meals.map((m) => (
                      <MenuItem
                        title={m.title}
                        photo={m.photo}
                        description={m.description}
                        size={m.size}
                        price={m.price}
                        alergenes={m.alergenes}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>

      </main>
    </Layout>
  )
}

export default MeniPage

export const Head = () => (
  <Seo />
)
