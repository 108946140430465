import * as React from "react"
import {MenuContent} from "./MenuContent"

export function MenuTabs({setActiveTab, activeTab}) {
  return (
    <ul className="nav page-tabs-nav">

      {MenuContent.map((t) => (
        <li>
          <a
            onClick={(e) => { e.preventDefault(); setActiveTab(t.number)}}
            className={`tab-nav-item ${activeTab === t.number && " active"}`}
            id={`${t.link}-tab`}
            data-toggle="tab"
            href={`#${t.link}`}
            role="tab"
            aria-controls={`${t.link}`}
            aria-selected="true">
              {t.title}
            </a>
        </li>
      ))}

    </ul>
  )
}
